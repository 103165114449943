import styles from "./NavBar.module.css";
import { useState, useEffect } from "react";
const NavBar = () => {
  const [colorChange, setColorchange] = useState(false);
  const [toggle, setToggle] = useState(false);
  const changeNavbarColor = () => {
    setToggle(false);
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  useEffect(() => {
    // 使用瀏覽器 API 更新文件標題
    window.addEventListener("scroll", changeNavbarColor);
  });
  return (
    <nav
      className={`${styles.nav}  ${toggle ? styles.show : ""} ${
        colorChange ? "colorChange" : ""
      }`}
    >
      <div className={styles.navWrapper}>
        <div
          className={`${styles.navBackground}  ${toggle ? styles.show : ""}`}
        ></div>
        <div className={styles.navContent}>
          {/*  <a href="." className={`nav-title ${styles.navLogo} ${styles.logo}`}></a> */}
          <div className={styles.navTitle}>
            <a className={`logo-text`} href="https://deframe.xyz">
              DeFrame
            </a>
          </div>
          <div className={`${styles.navMenu} ${toggle ? styles.show : ""}`}>
            <div className={styles.menuTray}>
              <ul className={styles.menuItems}>
                <li className={styles.menuItem}>
                  <a href="https://deframe.xyz/" className={styles.navItem}>
                    Overview
                  </a>
                </li>
                <li className={styles.menuItem}>
                  <a
                    href="https://deframe.xyz/tech-spec"
                    className={styles.navItem}
                  >
                    Tech Specs
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.lnAcitons}>
              <div
                className={`${styles.lnAciton}  ${styles.lnActionMenucta}`}
                onClick={() => setToggle(!toggle)}
              >
                <label htmlFor="ac-ln-menustate" className={styles.lnMenucta}>
                  <span
                    className={`${styles.lnMenuctaChevron}  ${
                      toggle ? styles.show : ""
                    }`}
                  ></span>
                </label>
              </div>
              <div className={`${styles.lnAciton}  ${styles.InActionButton} `}>
                <a
                  href="https://store.deframe.xyz"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.AclnButton}
                >
                  Buy <span className="ActionProduct"></span>
                </a>
              </div>
            </div>
          </div>
          {/*  <div className={`${styles.navIcon}`} onClick={() => setToggle(!toggle)}>
                        <div className={`${styles.bar} ${styles.one}`}></div>
                        <div className={`${styles.bar} ${styles.two}`}></div>
                    </div> */}

          {/* <div className={`${styles.navLinks}`}>
                        <Link href="/" ><a>Overview</a></Link>
                        <Link href="/tech-spec" ><a>Tech Specs</a></Link>
                        <Link href="/" ><a>Pre-order</a></Link>
                    </div> */}
        </div>
      </div>
    </nav>
  );
};
export default NavBar;
