/* eslint-disable */
import { useCallback, useEffect, useMemo, useState } from "react";
import * as anchor from "@project-serum/anchor";
import styled from "styled-components";
import { Container, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import {
  Commitment,
  Connection,
  PublicKey,
  Transaction,
} from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import {
  awaitTransactionSignatureConfirmation,
  CANDY_MACHINE_PROGRAM,
  CandyMachineAccount,
  createAccountsForMint,
  getCandyMachineState,
  getCollectionPDA,
  mintOneToken,
  SetupState,
} from "./candy-machine";
import { AlertState, getAtaForMint } from "./utils";
import { MintButton } from "./MintButton";
import NavBar from "./NavBar";
import { GatewayProvider } from "@civic/solana-gateway-react";
// import { sendTransaction } from "./connection";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import axios from "axios";

const ConnectButton = styled(WalletDialogButton)`
  background-color: black;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  max-width: 10rem;
  height: 40px;
  :hover {
    color: white !important;
    background-color: #1d1d1f !important;
  }
`;

const MintContainer = styled.div``; // add your owns styles here

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
  network: WalletAdapterNetwork;
  error?: string;
}

const Home = (props: HomeProps) => {
  const isReady = true;
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });
  const [isActive, setIsActive] = useState(false);
  // const [endDate, setEndDate] = useState<Date>();
  const [itemsRemaining, setItemsRemaining] = useState<number>();
  const [isWhitelistUser, setIsWhitelistUser] = useState(false);
  const [isPresale, setIsPresale] = useState(false);
  const [isValidBalance, setIsValidBalance] = useState(false);
  const [orderDetail, setOrderDetail] = useState({
    charcoal_quantity: 0,
    silver_quantity: 0,
    transaction_status: "",
    engraving_option: "deframe-silver-gray_degod",
  });
  // const [discountPrice, setDiscountPrice] = useState<anchor.BN>();
  const [needTxnSplit, setNeedTxnSplit] = useState(true);
  const [setupTxn, setSetupTxn] = useState<SetupState>();

  const rpcUrl = props.rpcHost;
  const wallet = useWallet();
  const cluster = props.network;

  useEffect(() => {
    axios
      .get(
        `https://drsn0ret64.execute-api.ap-southeast-1.amazonaws.com/dev/orders?wallet_address=${wallet.publicKey?.toBase58()}`
      )
      .then(function (response) {
        console.log(response.data);
        console.log(response.data.charcoal_quantity);
        console.log(response.data.silver_quantity);
        setOrderDetail({
          charcoal_quantity: response.data.charcoal_quantity,
          silver_quantity: response.data.silver_quantity,
          transaction_status: response.data.transaction_status,
          engraving_option: response.data.engraving_option,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [wallet.publicKey]);

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(
    async (commitment: Commitment = "confirmed") => {
      if (!anchorWallet) {
        return;
      }
      if (props.error !== undefined) {
        setAlertState({
          open: true,
          message: props.error,
          severity: "error",
          hideDuration: null,
        });
        return;
      }

      const connection = new Connection(props.rpcHost, commitment);

      if (props.candyMachineId) {
        try {
          const cndy = await getCandyMachineState(
            anchorWallet,
            props.candyMachineId,
            connection
          );
          console.log("Candy machine state: ", cndy);
          let active = cndy?.state.goLiveDate
            ? cndy?.state.goLiveDate.toNumber() < new Date().getTime() / 1000
            : false;
          let presale = false;

          // duplication of state to make sure we have the right values!
          let isWLUser = false;
          let userPrice = cndy.state.price;

          // whitelist mint?
          if (cndy?.state.whitelistMintSettings) {
            // is it a presale mint?
            if (
              cndy.state.whitelistMintSettings.presale &&
              (!cndy.state.goLiveDate ||
                cndy.state.goLiveDate.toNumber() > new Date().getTime() / 1000)
            ) {
              presale = true;
            }
            // is there a discount?
            if (cndy.state.whitelistMintSettings.discountPrice) {
              // setDiscountPrice(cndy.state.whitelistMintSettings.discountPrice);s
              userPrice = cndy.state.whitelistMintSettings.discountPrice;
            } else {
              // setDiscountPrice(undefined);
              // when presale=false and discountPrice=null, mint is restricted
              // to whitelist users only
              if (!cndy.state.whitelistMintSettings.presale) {
                cndy.state.isWhitelistOnly = true;
              }
            }
            // retrieves the whitelist token
            const mint = new anchor.web3.PublicKey(
              cndy.state.whitelistMintSettings.mint
            );
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];

            try {
              const balance = await connection.getTokenAccountBalance(token);
              isWLUser = parseInt(balance.value.amount) > 0;
              // only whitelist the user if the balance > 0
              setIsWhitelistUser(isWLUser);

              if (cndy.state.isWhitelistOnly) {
                active = isWLUser && (presale || active);
              }
            } catch (e) {
              setIsWhitelistUser(false);
              // no whitelist user, no mint
              if (cndy.state.isWhitelistOnly) {
                active = false;
              }
              console.log(
                "There was a problem fetching whitelist token balance"
              );
              console.log(e);
            }
          }
          userPrice = isWLUser ? userPrice : cndy.state.price;

          if (cndy?.state.tokenMint) {
            // retrieves the SPL token
            const mint = new anchor.web3.PublicKey(cndy.state.tokenMint);
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];
            try {
              const balance = await connection.getTokenAccountBalance(token);

              const valid = new anchor.BN(balance.value.amount).gte(userPrice);

              // only allow user to mint if token balance >  the user if the balance > 0
              setIsValidBalance(valid);
              active = active && valid;
            } catch (e) {
              setIsValidBalance(false);
              active = false;
              // no whitelist user, no mint
              console.log("There was a problem fetching SPL token balance");
              console.log(e);
            }
          } else {
            const balance = new anchor.BN(
              await connection.getBalance(anchorWallet.publicKey)
            );
            const valid = balance.gte(userPrice);
            setIsValidBalance(valid);
            active = active && valid;
          }

          // datetime to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.date) {
            // setEndDate(toDate(cndy.state.endSettings.number));
            if (
              cndy.state.endSettings.number.toNumber() <
              new Date().getTime() / 1000
            ) {
              active = false;
            }
          }
          // amount to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.amount) {
            const limit = Math.min(
              cndy.state.endSettings.number.toNumber(),
              cndy.state.itemsAvailable
            );
            if (cndy.state.itemsRedeemed < limit) {
              setItemsRemaining(limit - cndy.state.itemsRedeemed);
            } else {
              setItemsRemaining(0);
              cndy.state.isSoldOut = true;
            }
          } else {
            setItemsRemaining(cndy.state.itemsRemaining);
          }

          if (cndy.state.isSoldOut) {
            active = false;
          }

          const [collectionPDA] = await getCollectionPDA(props.candyMachineId);
          const collectionPDAAccount = await connection.getAccountInfo(
            collectionPDA
          );

          setIsActive((cndy.state.isActive = active));
          setIsPresale((cndy.state.isPresale = presale));
          setCandyMachine(cndy);

          const txnEstimate =
            892 +
            (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
            (cndy.state.tokenMint ? 66 : 0) +
            (cndy.state.whitelistMintSettings ? 34 : 0) +
            (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
            (cndy.state.gatekeeper ? 33 : 0) +
            (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

          setNeedTxnSplit(txnEstimate > 1230);
        } catch (e) {
          if (e instanceof Error) {
            if (
              e.message === `Account does not exist ${props.candyMachineId}`
            ) {
              setAlertState({
                open: true,
                message: `Couldn't fetch candy machine state from candy machine with address: ${props.candyMachineId}, using rpc: ${props.rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`,
                severity: "error",
                hideDuration: null,
              });
            } else if (
              e.message.startsWith("failed to get info about account")
            ) {
              setAlertState({
                open: true,
                message: `Couldn't fetch candy machine state with rpc: ${props.rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`,
                severity: "error",
                hideDuration: null,
              });
            }
          } else {
            setAlertState({
              open: true,
              message: `${e}`,
              severity: "error",
              hideDuration: null,
            });
          }
          console.log(e);
        }
      } else {
        setAlertState({
          open: true,
          message: `Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!`,
          severity: "error",
          hideDuration: null,
        });
      }
    },
    [anchorWallet, props.candyMachineId, props.error, props.rpcHost]
  );

  const onMint = async (
    beforeTransactions: Transaction[] = [],
    afterTransactions: Transaction[] = []
  ) => {
    try {
      setIsUserMinting(true);
      document.getElementById("#identity")?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        let setupMint: SetupState | undefined;
        if (needTxnSplit && setupTxn === undefined) {
          setAlertState({
            open: true,
            message: "Please sign account setup transaction",
            severity: "info",
          });
          setupMint = await createAccountsForMint(
            candyMachine,
            wallet.publicKey
          );
          let status: any = { err: true };
          if (setupMint.transaction) {
            status = await awaitTransactionSignatureConfirmation(
              setupMint.transaction,
              props.txTimeout,
              props.connection,
              true
            );
          }
          if (status && !status.err) {
            setSetupTxn(setupMint);
            setAlertState({
              open: true,
              message:
                "Setup transaction succeeded! Please sign minting transaction",
              severity: "info",
            });
          } else {
            setAlertState({
              open: true,
              message: "Mint failed! Please try again!",
              severity: "error",
            });
            setIsUserMinting(false);
            return;
          }
        } else {
          setAlertState({
            open: true,
            message: "Please sign minting transaction",
            severity: "info",
          });
        }

        const mintResult = await mintOneToken(
          candyMachine,
          wallet.publicKey,
          beforeTransactions,
          afterTransactions,
          setupMint ?? setupTxn
        );

        let status: any = { err: true };
        let metadataStatus = null;
        if (mintResult) {
          status = await awaitTransactionSignatureConfirmation(
            mintResult.mintTxId,
            props.txTimeout,
            props.connection,
            true
          );

          metadataStatus =
            await candyMachine.program.provider.connection.getAccountInfo(
              mintResult.metadataKey,
              "processed"
            );
          console.log("Metadata status: ", !!metadataStatus);
        }

        if (status && !status.err && metadataStatus) {
          // manual update since the refresh might not detect
          // the change immediately
          const remaining = itemsRemaining! - 1;
          setItemsRemaining(remaining);
          setIsActive((candyMachine.state.isActive = remaining > 0));
          candyMachine.state.isSoldOut = remaining === 0;
          setSetupTxn(undefined);
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
            hideDuration: 7000,
          });
          refreshCandyMachineState("processed");
        } else if (status && !status.err) {
          setAlertState({
            open: true,
            message:
              "Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.",
            severity: "error",
            hideDuration: 8000,
          });
          refreshCandyMachineState();
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
          refreshCandyMachineState();
        }
      }
    } catch (error: any) {
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (!error.message) {
          message = "Transaction timeout! Please try again.";
        } else if (error.message.indexOf("0x137")) {
          console.log(error);
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          console.log(error);
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
      // updates the candy machine state to reflect the latest
      // information on chain
      refreshCandyMachineState();
    } finally {
      setIsUserMinting(false);
    }
  };

  // const toggleMintButton = () => {
  //   let active = !isActive || isPresale;

  //   if (active) {
  //     if (candyMachine!.state.isWhitelistOnly && !isWhitelistUser) {
  //       active = false;
  //     }
  //     if (endDate && Date.now() >= endDate.getTime()) {
  //       active = false;
  //     }
  //   }

  //   if (
  //     isPresale &&
  //     candyMachine!.state.goLiveDate &&
  //     candyMachine!.state.goLiveDate.toNumber() <= new Date().getTime() / 1000
  //   ) {
  //     setIsPresale((candyMachine!.state.isPresale = false));
  //   }

  //   setIsActive((candyMachine!.state.isActive = active));
  // };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  useEffect(() => {
    (function loop() {
      setTimeout(() => {
        refreshCandyMachineState();
        loop();
      }, 20000);
    })();
  }, [refreshCandyMachineState]);

  const getCharcoalOrder = (quantity: Number) => {
    const charcoal_engravings = [] as any;
    orderDetail.engraving_option.split("+").forEach((option) => {
      const engraving = option.split("_")[1];
      if (option.split("_")[0] == "deframe-charcoal") {
        charcoal_engravings.push(engraving);
        /*       console.log(engraving);
        console.log("charcoal_engravings"); */
      }
    });
    if (quantity == 0) {
      return <></>;
    } else if (quantity == 1) {
      return (
        <div className="order-wrapper ">
          <Grid
            container
            spacing={4}
            style={{
              marginTop: "4em",
              width: "1080px",
              overflow: "auto",
            }}
          >
            <Grid item xs={3}>
              <img
                style={{
                  maxWidth: "100%",
                }}
                src={
                  charcoal_engravings[0] !== "standard" ? "4.webp" : "2.webp"
                }
              ></img>
            </Grid>
            <Grid
              item
              xs={9}
              style={{
                maxWidth: "75%",
                paddingLeft: "2rem",
                paddingRight: "2rem",
              }}
            >
              <h1 className="item-heading">DeFrame - Charcoal Grey</h1>
              <div className="progress">
                <div className="progress-bar" style={{ width: "25%" }}></div>
              </div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <p className="progress-text">Order Placed</p>
                  </Grid>
                  <Grid item xs={3}>
                    <p
                      className="progress-text"
                      style={{ textAlign: "center", color: "#6e6e73" }}
                    >
                      Manufacturing
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <p
                      className="progress-text"
                      style={{ textAlign: "center", color: "#6e6e73" }}
                    >
                      Quality Assurance
                    </p>
                  </Grid>
                  <Grid item xs={3}>
                    <p
                      className="progress-text"
                      style={{ textAlign: "center", color: "#6e6e73" }}
                    >
                      Shipped
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <p
                      className="progress-text"
                      style={{ textAlign: "end", color: "#6e6e73" }}
                    >
                      Delivered
                    </p>
                  </Grid>
                </Grid>
              </div>
              <hr></hr>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "65%",
                  }}
                >
                  <p className="description-content">DeFrame Genesis Cube</p>
                  <p className="mini-description-content">
                    DeFrame Genesis Cube is ready. Redeem it and enjoy utility,
                    airdrops to holders and build Monumint Labs together.
                  </p>
                </div>
                {isReady && !wallet.connected ? (
                  <ConnectButton>Connect Wallet</ConnectButton>
                ) : (
                  isReady && (
                    <>
                      <MintContainer>
                        {candyMachine?.state.isActive &&
                        candyMachine?.state.gatekeeper &&
                        wallet.publicKey &&
                        wallet.signTransaction ? (
                          <GatewayProvider
                            wallet={{
                              publicKey:
                                wallet.publicKey ||
                                new PublicKey(CANDY_MACHINE_PROGRAM),
                              //@ts-ignore
                              signTransaction: wallet.signTransaction,
                            }}
                            gatekeeperNetwork={
                              candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                            }
                            clusterUrl={rpcUrl}
                            cluster={cluster}
                            options={{ autoShowModal: false }}
                          >
                            <MintButton
                              candyMachine={candyMachine}
                              isMinting={isUserMinting}
                              setIsMinting={(val) => setIsUserMinting(val)}
                              onMint={onMint}
                              isActive={
                                isActive ||
                                (isPresale && isWhitelistUser && isValidBalance)
                              }
                            />
                          </GatewayProvider>
                        ) : (
                          isReady && (
                            <MintButton
                              candyMachine={candyMachine}
                              isMinting={isUserMinting}
                              setIsMinting={(val) => setIsUserMinting(val)}
                              onMint={onMint}
                              isActive={
                                isActive ||
                                (isPresale && isWhitelistUser && isValidBalance)
                              }
                            />
                          )
                        )}
                      </MintContainer>
                    </>
                  )
                )}
              </div>
              <hr></hr>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "65%",
                  }}
                >
                  <p className="description-content">Logo Engraving</p>
                </div>
                <p className="mini-description-text">
                  {charcoal_engravings[0] !== "standard"
                    ? "DeGods"
                    : "Standard"}
                </p>
              </div>
              <hr></hr>
            </Grid>
          </Grid>
        </div>
      );
    } else if (quantity == 2) {
      return (
        <>
          <div className="order-wrapper">
            <Grid
              container
              spacing={4}
              style={{
                marginTop: "4em",
                width: "1080px",
                overflow: "auto",
              }}
            >
              <Grid item xs={3}>
                <img
                  style={{
                    maxWidth: "100%",
                  }}
                  src={
                    charcoal_engravings[0] !== "standard" ? "4.webp" : "2.webp"
                  }
                ></img>
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  maxWidth: "75%",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <h1 className="item-heading">DeFrame - Charcoal Grey</h1>
                <div className="progress">
                  <div className="progress-bar" style={{ width: "25%" }}></div>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <p className="progress-text">Order Placed</p>
                    </Grid>
                    <Grid item xs={3}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Manufacturing
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Quality Assurance
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Shipped
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "end", color: "#6e6e73" }}
                      >
                        Delivered
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <hr></hr>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "65%",
                    }}
                  >
                    <p className="description-content">
                      DeFrame Genesis Cube
                    </p>
                    <p className="mini-description-content">
                      DeFrame Genesis Cube is ready. Redeem it and enjoy
                      utility, airdrops to holders and build Monumint Labs
                      together.
                    </p>
                  </div>
                  {isReady && !wallet.connected ? (
                    <ConnectButton>Connect Wallet</ConnectButton>
                  ) : (
                    isReady && (
                      <>
                        <MintContainer>
                          {candyMachine?.state.isActive &&
                          candyMachine?.state.gatekeeper &&
                          wallet.publicKey &&
                          wallet.signTransaction ? (
                            <GatewayProvider
                              wallet={{
                                publicKey:
                                  wallet.publicKey ||
                                  new PublicKey(CANDY_MACHINE_PROGRAM),
                                //@ts-ignore
                                signTransaction: wallet.signTransaction,
                              }}
                              gatekeeperNetwork={
                                candyMachine?.state?.gatekeeper
                                  ?.gatekeeperNetwork
                              }
                              clusterUrl={rpcUrl}
                              cluster={cluster}
                              options={{ autoShowModal: false }}
                            >
                              <MintButton
                                candyMachine={candyMachine}
                                isMinting={isUserMinting}
                                setIsMinting={(val) => setIsUserMinting(val)}
                                onMint={onMint}
                                isActive={
                                  isActive ||
                                  (isPresale &&
                                    isWhitelistUser &&
                                    isValidBalance)
                                }
                              />
                            </GatewayProvider>
                          ) : (
                            <MintButton
                              candyMachine={candyMachine}
                              isMinting={isUserMinting}
                              setIsMinting={(val) => setIsUserMinting(val)}
                              onMint={onMint}
                              isActive={
                                isActive ||
                                (isPresale && isWhitelistUser && isValidBalance)
                              }
                            />
                          )}
                        </MintContainer>
                      </>
                    )
                  )}
                </div>
                <hr></hr>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "65%",
                    }}
                  >
                    <p className="description-content">Logo Engraving</p>
                  </div>
                  <p className="mini-description-text">
                    {charcoal_engravings[0] !== "standard"
                      ? "DeGods"
                      : "Standard"}
                  </p>
                </div>
                <hr></hr>
              </Grid>
            </Grid>
          </div>
          <div className="order-wrapper">
            <Grid
              container
              spacing={4}
              style={{
                marginTop: "4em",
                width: "1080px",
                overflow: "auto",
              }}
            >
              <Grid item xs={3}>
                <img
                  style={{
                    maxWidth: "100%",
                  }}
                  src={
                    charcoal_engravings[1] !== "standard" ? "4.webp" : "2.webp"
                  }
                ></img>
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  maxWidth: "75%",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <h1 className="item-heading">DeFrame - Charcoal Grey</h1>
                <div className="progress">
                  <div className="progress-bar" style={{ width: "25%" }}></div>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <p className="progress-text">Order Placed</p>
                    </Grid>
                    <Grid item xs={3}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Manufacturing
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Quality Assurance
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Shipped
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "end", color: "#6e6e73" }}
                      >
                        Delivered
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <hr></hr>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "65%",
                    }}
                  >
                    <p className="description-content">
                      DeFrame Genesis Cube
                    </p>
                    <p className="mini-description-content">
                      DeFrame Genesis Cube is ready. Redeem it and enjoy
                      utility, airdrops to holders and build Monumint Labs
                      together.
                    </p>
                  </div>
                  {!wallet.connected && isReady ? (
                    <ConnectButton>Connect Wallet</ConnectButton>
                  ) : (
                    isReady && (
                      <>
                        <MintContainer>
                          {candyMachine?.state.isActive &&
                          candyMachine?.state.gatekeeper &&
                          wallet.publicKey &&
                          wallet.signTransaction ? (
                            <GatewayProvider
                              wallet={{
                                publicKey:
                                  wallet.publicKey ||
                                  new PublicKey(CANDY_MACHINE_PROGRAM),
                                //@ts-ignore
                                signTransaction: wallet.signTransaction,
                              }}
                              gatekeeperNetwork={
                                candyMachine?.state?.gatekeeper
                                  ?.gatekeeperNetwork
                              }
                              clusterUrl={rpcUrl}
                              cluster={cluster}
                              options={{ autoShowModal: false }}
                            >
                              <MintButton
                                candyMachine={candyMachine}
                                isMinting={isUserMinting}
                                setIsMinting={(val) => setIsUserMinting(val)}
                                onMint={onMint}
                                isActive={
                                  isActive ||
                                  (isPresale &&
                                    isWhitelistUser &&
                                    isValidBalance)
                                }
                              />
                            </GatewayProvider>
                          ) : (
                            <MintButton
                              candyMachine={candyMachine}
                              isMinting={isUserMinting}
                              setIsMinting={(val) => setIsUserMinting(val)}
                              onMint={onMint}
                              isActive={
                                isActive ||
                                (isPresale && isWhitelistUser && isValidBalance)
                              }
                            />
                          )}
                        </MintContainer>
                      </>
                    )
                  )}
                </div>
                <hr></hr>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "65%",
                    }}
                  >
                    <p className="description-content">Logo Engraving</p>
                  </div>
                  <p className="mini-description-text">
                    {charcoal_engravings[1] !== "standard"
                      ? "DeGods"
                      : "Standard"}
                  </p>
                </div>
                <hr></hr>
              </Grid>
            </Grid>
          </div>
        </>
      );
    }
  };

  const getSilverOrder = (quantity: Number) => {
    const silver_engravings = [] as any;
    orderDetail.engraving_option.split("+").forEach((option) => {
      const engraving = option.split("_")[1];
      if (option.split("_")[0] == "deframe-silver-gray") {
        silver_engravings.push(engraving);
      }
    });
    if (quantity == 0) {
      return <></>;
    } else if (quantity == 1) {
      return (
        <div className="order-wrapper">
          <Grid
            container
            spacing={4}
            style={{
              marginTop: "4em",
              width: "1080px",
              overflow: "auto",
            }}
          >
            <Grid item xs={3}>
              <img
                style={{
                  maxWidth: "100%",
                }}
                src={silver_engravings[0] !== "standard" ? "3.webp" : "1.webp"}
              ></img>
            </Grid>
            <Grid
              item
              xs={9}
              style={{
                maxWidth: "75%",
                paddingLeft: "2rem",
                paddingRight: "2rem",
              }}
            >
              <h1 className="item-heading">DeFrame - Silver Grey</h1>
              <div className="progress">
                <div className="progress-bar" style={{ width: "25%" }}></div>
              </div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <p className="progress-text">Order Placed</p>
                  </Grid>
                  <Grid item xs={3}>
                    <p
                      className="progress-text"
                      style={{ textAlign: "center", color: "#6e6e73" }}
                    >
                      Manufacturing
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <p
                      className="progress-text"
                      style={{ textAlign: "center", color: "#6e6e73" }}
                    >
                      Quality Assurance
                    </p>
                  </Grid>
                  <Grid item xs={3}>
                    <p
                      className="progress-text"
                      style={{ textAlign: "center", color: "#6e6e73" }}
                    >
                      Shipped
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <p
                      className="progress-text"
                      style={{ textAlign: "end", color: "#6e6e73" }}
                    >
                      Delivered
                    </p>
                  </Grid>
                </Grid>
              </div>
              <hr></hr>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "65%",
                  }}
                >
                  <p className="description-content">DeFrame Genesis Cube</p>
                  <p className="mini-description-content">
                    DeFrame Genesis Cube is ready. Redeem it and enjoy utility,
                    airdrops to holders and build Monumint Labs together.
                  </p>
                </div>
                {!wallet.connected && isReady ? (
                  <ConnectButton>Connect Wallet</ConnectButton>
                ) : (
                  isReady && (
                    <>
                      <MintContainer>
                        {candyMachine?.state.isActive &&
                        candyMachine?.state.gatekeeper &&
                        wallet.publicKey &&
                        wallet.signTransaction ? (
                          <GatewayProvider
                            wallet={{
                              publicKey:
                                wallet.publicKey ||
                                new PublicKey(CANDY_MACHINE_PROGRAM),
                              //@ts-ignore
                              signTransaction: wallet.signTransaction,
                            }}
                            gatekeeperNetwork={
                              candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                            }
                            clusterUrl={rpcUrl}
                            cluster={cluster}
                            options={{ autoShowModal: false }}
                          >
                            <MintButton
                              candyMachine={candyMachine}
                              isMinting={isUserMinting}
                              setIsMinting={(val) => setIsUserMinting(val)}
                              onMint={onMint}
                              isActive={
                                isActive ||
                                (isPresale && isWhitelistUser && isValidBalance)
                              }
                            />
                          </GatewayProvider>
                        ) : (
                          <MintButton
                            candyMachine={candyMachine}
                            isMinting={isUserMinting}
                            setIsMinting={(val) => setIsUserMinting(val)}
                            onMint={onMint}
                            isActive={
                              isActive ||
                              (isPresale && isWhitelistUser && isValidBalance)
                            }
                          />
                        )}
                      </MintContainer>
                    </>
                  )
                )}
                {/* <button className="mintButton" disabled>
                  Preparing
                </button> */}
              </div>
              <hr></hr>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    maxWidth: "65%",
                  }}
                >
                  <p className="description-content">Logo Engraving</p>
                </div>
                <p className="mini-description-text">
                  {silver_engravings[0] !== "standard" ? "DeGods" : "Standard"}
                </p>
              </div>
              <hr></hr>
            </Grid>
          </Grid>
        </div>
      );
    } else if (quantity == 2) {
      return (
        <>
          <div className="order-wrapper">
            <Grid
              container
              spacing={4}
              style={{
                marginTop: "4em",
                width: "1080px",
                overflow: "auto",
              }}
            >
              <Grid item xs={3}>
                <img
                  style={{
                    maxWidth: "100%",
                  }}
                  src={
                    silver_engravings[0] !== "standard" ? "3.webp" : "1.webp"
                  }
                ></img>
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  maxWidth: "75%",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <h1 className="item-heading">DeFrame - Silver Grey</h1>
                <div className="progress">
                  <div className="progress-bar" style={{ width: "25%" }}></div>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <p className="progress-text">Order Placed</p>
                    </Grid>
                    <Grid item xs={3}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Manufacturing
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Quality Assurance
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Shipped
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "end", color: "#6e6e73" }}
                      >
                        Delivered
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <hr></hr>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "65%",
                    }}
                  >
                    <p className="description-content">
                      DeFrame Genesis Cube
                    </p>
                    <p className="mini-description-content">
                      DeFrame Genesis Cube is ready. Redeem it and enjoy
                      utility, airdrops to holders and build Monumint Labs
                      together.
                    </p>
                  </div>
                  {!wallet.connected && isReady ? (
                    <ConnectButton>Connect Wallet</ConnectButton>
                  ) : (
                    isReady && (
                      <>
                        <MintContainer>
                          {candyMachine?.state.isActive &&
                          candyMachine?.state.gatekeeper &&
                          wallet.publicKey &&
                          wallet.signTransaction ? (
                            <GatewayProvider
                              wallet={{
                                publicKey:
                                  wallet.publicKey ||
                                  new PublicKey(CANDY_MACHINE_PROGRAM),
                                //@ts-ignore
                                signTransaction: wallet.signTransaction,
                              }}
                              gatekeeperNetwork={
                                candyMachine?.state?.gatekeeper
                                  ?.gatekeeperNetwork
                              }
                              clusterUrl={rpcUrl}
                              cluster={cluster}
                              options={{ autoShowModal: false }}
                            >
                              <MintButton
                                candyMachine={candyMachine}
                                isMinting={isUserMinting}
                                setIsMinting={(val) => setIsUserMinting(val)}
                                onMint={onMint}
                                isActive={
                                  isActive ||
                                  (isPresale &&
                                    isWhitelistUser &&
                                    isValidBalance)
                                }
                              />
                            </GatewayProvider>
                          ) : (
                            <MintButton
                              candyMachine={candyMachine}
                              isMinting={isUserMinting}
                              setIsMinting={(val) => setIsUserMinting(val)}
                              onMint={onMint}
                              isActive={
                                isActive ||
                                (isPresale && isWhitelistUser && isValidBalance)
                              }
                            />
                          )}
                        </MintContainer>
                      </>
                    )
                  )}
                </div>
                <hr></hr>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "65%",
                    }}
                  >
                    <p className="description-content">Logo Engraving</p>
                  </div>
                  <p className="mini-description-text">
                    {silver_engravings[0] !== "standard"
                      ? "DeGods"
                      : "Standard"}
                  </p>
                </div>
                <hr></hr>
              </Grid>
            </Grid>
          </div>
          <div className="order-wrapper">
            <Grid
              container
              spacing={4}
              style={{
                marginTop: "4em",
                width: "1080px",
                overflow: "auto",
              }}
            >
              <Grid item xs={3}>
                <img
                  style={{
                    maxWidth: "100%",
                  }}
                  src={
                    silver_engravings[1] !== "standard" ? "3.webp" : "1.webp"
                  }
                ></img>
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  maxWidth: "75%",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <h1 className="item-heading">DeFrame - Silver Grey</h1>
                <div className="progress">
                  <div className="progress-bar" style={{ width: "25%" }}></div>
                </div>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <p className="progress-text">Order Placed</p>
                    </Grid>
                    <Grid item xs={3}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Manufacturing
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Quality Assurance
                      </p>
                    </Grid>
                    <Grid item xs={3}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "center", color: "#6e6e73" }}
                      >
                        Shipped
                      </p>
                    </Grid>
                    <Grid item xs={2}>
                      <p
                        className="progress-text"
                        style={{ textAlign: "end", color: "#6e6e73" }}
                      >
                        Delivered
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <hr></hr>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "65%",
                    }}
                  >
                    <p className="description-content">
                      DeFrame Genesis Cube
                    </p>
                    <p className="mini-description-content">
                      DeFrame Genesis Cube is ready. Redeem it and enjoy
                      utility, airdrops to holders and build Monumint Labs
                      together.
                    </p>
                  </div>
                  {!wallet.connected && isReady ? (
                    <ConnectButton>Connect Wallet</ConnectButton>
                  ) : (
                    isReady && (
                      <>
                        <MintContainer>
                          {candyMachine?.state.isActive &&
                          candyMachine?.state.gatekeeper &&
                          wallet.publicKey &&
                          wallet.signTransaction ? (
                            <GatewayProvider
                              wallet={{
                                publicKey:
                                  wallet.publicKey ||
                                  new PublicKey(CANDY_MACHINE_PROGRAM),
                                //@ts-ignore
                                signTransaction: wallet.signTransaction,
                              }}
                              gatekeeperNetwork={
                                candyMachine?.state?.gatekeeper
                                  ?.gatekeeperNetwork
                              }
                              clusterUrl={rpcUrl}
                              cluster={cluster}
                              options={{ autoShowModal: false }}
                            >
                              <MintButton
                                candyMachine={candyMachine}
                                isMinting={isUserMinting}
                                setIsMinting={(val) => setIsUserMinting(val)}
                                onMint={onMint}
                                isActive={
                                  isActive ||
                                  (isPresale &&
                                    isWhitelistUser &&
                                    isValidBalance)
                                }
                              />
                            </GatewayProvider>
                          ) : (
                            <MintButton
                              candyMachine={candyMachine}
                              isMinting={isUserMinting}
                              setIsMinting={(val) => setIsUserMinting(val)}
                              onMint={onMint}
                              isActive={
                                isActive ||
                                (isPresale && isWhitelistUser && isValidBalance)
                              }
                            />
                          )}
                        </MintContainer>
                      </>
                    )
                  )}
                </div>
                <hr></hr>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "65%",
                    }}
                  >
                    <p className="description-content">Logo Engraving</p>
                  </div>
                  <p className="mini-description-text">
                    {silver_engravings[1] !== "standard"
                      ? "DeGods"
                      : "Standard"}
                  </p>
                </div>
                <hr></hr>
              </Grid>
            </Grid>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <NavBar />
      <Container style={{ marginTop: 75 }}>
        <Container style={{ position: "relative", maxWidth: "1080px" }}>
          <h1 className="heading">Thank you for your purchase.</h1>
          {<p style={{ display: "none" }}>{wallet.publicKey?.toBase58()}</p>}
          {wallet.publicKey && orderDetail.transaction_status == "Pending" && (
            <h3>Your transaction is still pending</h3>
          )}

          {orderDetail.transaction_status == "Confirmed" &&
            getSilverOrder(orderDetail.silver_quantity)}
          {orderDetail.transaction_status == "Confirmed" &&
            getCharcoalOrder(orderDetail.charcoal_quantity)}
          {wallet.publicKey ? (
            <p></p>
          ) : (
            <ConnectButton>Connect Wallet</ConnectButton>
          )}
        </Container>

        <Snackbar
          open={alertState.open}
          autoHideDuration={
            alertState.hideDuration === undefined
              ? 6000
              : alertState.hideDuration
          }
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

// const getCountdownDate = (
//   candyMachine: CandyMachineAccount
// ): Date | undefined => {
//   if (
//     candyMachine.state.isActive &&
//     candyMachine.state.endSettings?.endSettingType.date
//   ) {
//     return toDate(candyMachine.state.endSettings.number);
//   }

//   return toDate(
//     candyMachine.state.goLiveDate
//       ? candyMachine.state.goLiveDate
//       : candyMachine.state.isPresale
//         ? new anchor.BN(new Date().getTime() / 1000)
//         : undefined
//   );
// };

export default Home;
